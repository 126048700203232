@font-face {
    font-family: 'Söhne';
    font-style: normal;
    font-weight: 400;
    src: local(''),url('/assets/fonts/woff2/soehne-buch.woff2') format('woff2'),url('/assets/fonts/otf/Söhne-Buch.otf') format('otf');
    font-display: swap
}

@font-face {
    font-family: 'Söhne';
    font-style: italic;
    font-weight: 400;
    src: local(''),url('/assets/fonts/woff2/soehne-buch-kursiv.woff2') format('woff2'),url('/assets/fonts/otf/Söhne-BuchKursiv.otf') format('otf');
    font-display: swap
}

@font-face {
    font-family: 'Söhne';
    font-style: normal;
    font-weight: 600;
    src: local(''),url('/assets/fonts/woff2/soehne-halbfett.woff2') format('woff2'),url('/assets/fonts/otf/Söhne-Halbfett.otf') format('otf');
    font-display: swap
}

@font-face {
    font-family: 'Söhne';
    font-style: italic;
    font-weight: 600;
    src: local(''),url('/assets/fonts/woff2/soehne-halbfett-kursiv.woff2') format('woff2'),url('/assets/fonts/otf/Söhne-HalbfettKursiv.otf') format('otf');
    font-display: swap
}

// tailwind colors
$red400: #f87171;
$red500: #ef4444;
$blue400: #60a5fa;
$green400: #34d399;
input[type="button"]:disabled,
button:disabled {
    cursor: not-allowed !important;
}

.table-with-overflow > tbody {
    display: block;
    overflow: auto;
    max-height: 30rem;
    width: 100%;
  }
  
.table-with-overflow > tbody > *, .table-with-overflow > thead tr {
    display: inline-table;
    width: 100%;
}

// react toastify overrides
.Toastify__toast--error {
    background: $red500;
}
.Toastify__toast--info {
    background: $blue400;
}
.Toastify__toast--success {
    background: $green400;
}
.Toastify__toast-body {
    font-family: system-ui, -apple-system, /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        width: 100%;
}
